<template>
  <v-container fluid>
    <v-overlay
      :value="carga"
      opacity="1"
    >
      <v-progress-circular
        :size="200"
        color="red"
        indeterminate
      />
    </v-overlay>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-form
          ref="formClave"
          lazy-validation
        >
          <base-material-card
            width="100%"
            color="primary"
            icon="mdi-lock"
            title="Cambio de contraseña"
          >
            <v-card-title>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="claveUsuario"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    label="Clave nueva"
                    solo
                    dense
                    :rules="regla"
                    persistent-hint
                    @click:append="show = !show"
                  />
                  <v-btn
                    dark
                    block
                    color="primary"
                    @click="cambiarClave()"
                  >
                    CAMBIAR
                    <v-icon
                      right
                      dark
                    >
                      mdi-account-check
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </base-material-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from 'axios'
  export default {
    data: () => ({
      items: [],
      claveUsuario: '',
      dataUsuario: '',
      carga: true,
      regla: [v => !!v || 'El campo es requerido'],
      show: false,
    }),
    created () {
      if (!localStorage.getItem('usuarioAlien')) {
        this.cerrarSesion()
      } else {
        setTimeout(() => {
          this.carga = false
          this.$store.commit('SET_VALOR', false)
        }, 1000)
        this.dataUsuario = JSON.parse(localStorage.getItem('usuarioAlien'))
        this.validarToken()
      }
    },
    methods: {
      cerrarSesion () {
        this.$store.commit('SET_VALOR', true)
        localStorage.removeItem('usuarioAlien')
        this.$router.push('/')
      },
      validarToken () {
        if (this.dataUsuario.token) {
          const headers = { headers: { 'access-token': this.dataUsuario.token } }
          axios
            .get(`${window.__env.dataCfg.urlApiAlien}v0/token`, headers)
            .then((r) => {
              if (r.data.message !== true) {
                this.cerrarSesion()
              } else {
                this.carga = false
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
              this.carga = false
              this.cerrarSesion()
            })
        }
      },
      cambiarClave () {
        if (this.$refs.formClave.validate()) {
          const headers = { headers: { 'access-token': this.dataUsuario.token } }
          const data = { clave: this.claveUsuario, usuario: this.dataUsuario.usuario }
          axios
            .put(`${window.__env.dataCfg.urlApiAlien}v0/clave`, data, headers)
            .then(r => {
              if (r.data.message === true) {
                alert('Contraseña cambiada correctamente')
                this.$router.push('/consulta')
              } else {
                alert('No se pudo cambiar la clave')
              }
            })
            .catch(err => {
              alert(err)
            })
        }
      },
    },
  }
</script>
